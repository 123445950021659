import { lazyDefine } from '@github/catalyst';
// eslint-disable-next-line import/extensions
lazyDefine('animated-image', () => import('/github/app/components/accessibility/animated-image-element.ts'))
lazyDefine('launch-code', () => import('/github/app/components/account_verifications/launch-code-element.ts'))
lazyDefine('actions-base-filter.ts', () => import('/github/app/components/actions/actions-base-filter.ts'))
lazyDefine('actions-caches-filter', () => import('/github/app/components/actions/actions-caches-filter-element.ts'))
lazyDefine('actions-workflow-filter', () => import('/github/app/components/actions/actions-workflow-filter-element.ts'))
lazyDefine('metric-selection', () => import('/github/app/components/advisories/metric-selection-element.ts'))
lazyDefine('severity-calculator', () => import('/github/app/components/advisories/severity-calculator-element.ts'))
lazyDefine('severity-score', () => import('/github/app/components/advisories/severity-score-element.ts'))
lazyDefine('downgrade-dialog', () => import('/github/app/components/billing/settings/downgrade-dialog-element.ts'))
lazyDefine('downgrade-survey-dialog', () => import('/github/app/components/billing/settings/downgrade-survey-dialog-element.ts'))
lazyDefine('create-branch', () => import('/github/app/components/branch/create-branch-element.ts'))
lazyDefine('manage-seats', () => import('/github/app/components/businesses/billing/manage-seats-element.ts'))
lazyDefine('alert-dismissal-details', () => import('/github/app/components/code_scanning/alert-dismissal-details-element.ts'))
lazyDefine('sku-list', () => import('/github/app/components/codespaces/advanced_options/sku-list-element.ts'))
lazyDefine('create-button', () => import('/github/app/components/codespaces/create-button-element.ts'))
lazyDefine('command-palette-page', () => import('/github/app/components/command_palette/command-palette-page-element.ts'))
lazyDefine('command-palette-page-stack', () => import('/github/app/components/command_palette/command-palette-page-stack-element.ts'))
lazyDefine('query-search', () => import('/github/app/components/dashboard/query-search-element.ts'))
lazyDefine('dependabot-alert-dismissal', () => import('/github/app/components/dependabot_alerts/dependabot-alert-dismissal-element.ts'))
lazyDefine('dependabot-alert-load-all', () => import('/github/app/components/dependabot_alerts/dependabot-alert-load-all-element.ts'))
lazyDefine('dependabot-alert-row', () => import('/github/app/components/dependabot_alerts/dependabot-alert-row-element.ts'))
lazyDefine('dependabot-alert-table-header', () => import('/github/app/components/dependabot_alerts/dependabot-alert-table-header-element.ts'))
lazyDefine('deferred-diff-lines', () => import('/github/app/components/diffs/deferred-diff-lines-element.ts'))
lazyDefine('conduit-profile-feed-visibility', () => import('/github/app/components/feed/conduit-profile-feed-visibility-element.ts'))
lazyDefine('readme-toc', () => import('/github/app/components/files/readme-toc-element.ts'))
lazyDefine('delayed-loading', () => import('/github/app/components/github/delayed-loading-element.ts'))
lazyDefine('feature-callout', () => import('/github/app/components/github/feature-callout-element.ts'))
lazyDefine('remote-pagination', () => import('/github/app/components/github/remote-pagination-element.ts'))
lazyDefine('development-menu', () => import('/github/app/components/issues/references/development-menu-element.ts'))
lazyDefine('reopen-button', () => import('/github/app/components/issues/reopen-button-element.ts'))
lazyDefine('math-renderer', () => import('/github/app/components/mathjax/math-renderer-element.ts'))
lazyDefine('usage-tracker.ts', () => import('/github/app/components/mathjax/usage-tracker.ts'))
lazyDefine('entry-status-dialog', () => import('/github/app/components/merge_queues/entry-status-dialog-element.ts'))
lazyDefine('allowed-values-input', () => import('/github/app/components/organizations/settings/codespaces_policy_form/constraint_row/allowed-values-input-element.ts'))
lazyDefine('max-value', () => import('/github/app/components/organizations/settings/codespaces_policy_form/constraint_row/max-value-element.ts'))
lazyDefine('codespaces-policy-form', () => import('/github/app/components/organizations/settings/codespaces-policy-form-element.ts'))
lazyDefine('insights-form', () => import('/github/app/components/organizations/settings/features/insights-form-element.ts'))
lazyDefine('repository-selection-input', () => import('/github/app/components/packages/repository-selection-input-element.ts'))
lazyDefine('action-list', () => import('/github/app/components/primer/action_list/action-list-element.ts'))
lazyDefine('action-menu', () => import('/github/app/components/primer/action_menu/action-menu-element.ts'))
lazyDefine('split-page-layout', () => import('/github/app/components/primer/experimental/split-page-layout-element.ts'))
lazyDefine('toggle-switch', () => import('/github/app/components/primer/experimental/toggle-switch-element.ts'))
lazyDefine('lazy-load-section', () => import('/github/app/components/primer/navigation_list/lazy-load-section-element.ts'))
lazyDefine('profile-timezone', () => import('/github/app/components/profiles/profile-timezone-element.ts'))
lazyDefine('file-filter', () => import('/github/app/components/pull_requests/file_tree/file-filter-element.ts'))
lazyDefine('file-tree', () => import('/github/app/components/pull_requests/file_tree/file-tree-element.ts'))
lazyDefine('file-tree-toggle', () => import('/github/app/components/pull_requests/file_tree/file-tree-toggle-element.ts'))
lazyDefine('memex-project-picker', () => import('/github/app/components/repositories/memex/memex-project-picker-element.ts'))
lazyDefine('pin-organization-repo', () => import('/github/app/components/repositories/pin-organization-repo-element.ts'))
lazyDefine('styled-text-input', () => import('/github/app/components/search/input/styled-text-input-element.ts'))
lazyDefine('common.ts', () => import('/github/app/components/search/parsing/common.ts'))
lazyDefine('parsing.ts', () => import('/github/app/components/search/parsing/parsing.ts'))
lazyDefine('search-input', () => import('/github/app/components/search/search-input-element.ts'))
lazyDefine('blackbird.ts', () => import('/github/app/components/search/suggestions/blackbird.ts'))
lazyDefine('custom-scopes.ts', () => import('/github/app/components/search/suggestions/custom-scopes.ts'))
lazyDefine('default.ts', () => import('/github/app/components/search/suggestions/default.ts'))
lazyDefine('fixed-qualifiers.ts', () => import('/github/app/components/search/suggestions/fixed-qualifiers.ts'))
lazyDefine('history.ts', () => import('/github/app/components/search/suggestions/history.ts'))
lazyDefine('input.ts', () => import('/github/app/components/search/suggestions/input.ts'))
lazyDefine('languages.ts', () => import('/github/app/components/search/suggestions/languages.ts'))
lazyDefine('projects.ts', () => import('/github/app/components/search/suggestions/projects.ts'))
lazyDefine('repos.ts', () => import('/github/app/components/search/suggestions/repos.ts'))
lazyDefine('suggestions.ts', () => import('/github/app/components/search/suggestions/suggestions.ts'))
lazyDefine('teams.ts', () => import('/github/app/components/search/suggestions/teams.ts'))
lazyDefine('types.ts', () => import('/github/app/components/search/suggestions/types.ts'))
lazyDefine('project-picker', () => import('/github/app/components/sidebar/project-picker-element.ts'))
lazyDefine('testimonials-carousel', () => import('/github/app/components/site/contentful/customer_stories/testimonials-carousel-element.ts'))
lazyDefine('notification-indicator', () => import('/github/app/components/site/header/notification-indicator-element.ts'))
lazyDefine('slash-command-toolbar-button', () => import('/github/app/components/slash_commands/slash-command-toolbar-button-element.ts'))
lazyDefine('monthly-spend-graph', () => import('/github/app/components/sponsors/orgs/premium_dashboard/monthly-spend-graph-element.ts'))
lazyDefine('react-profiling-toggle', () => import('/github/app/components/stafftools/react/react-profiling-toggle-element.ts'))
lazyDefine('react-staffbar', () => import('/github/app/components/stafftools/react/react-staffbar-element.ts'))
lazyDefine('soft-nav-staffbar', () => import('/github/app/components/stafftools/soft_nav/soft-nav-staffbar-element.ts'))
lazyDefine('soft-nav-staffbar-preview', () => import('/github/app/components/stafftools/soft_nav/soft-nav-staffbar-preview-element.ts'))
lazyDefine('themed-picture', () => import('/github/app/components/themed_pictures/themed-picture-element.ts'))
lazyDefine('tracking-block-api.ts', () => import('/github/app/components/tracking_blocks/tracking-block-api.ts'))
lazyDefine('tracking-block', () => import('/github/app/components/tracking_blocks/tracking-block-element.ts'))
lazyDefine('tracking-block-omnibar', () => import('/github/app/components/tracking_blocks/tracking-block-omnibar-element.ts'))
lazyDefine('profile-pins', ()=>import('./github/profile/profile-pins-element'));
lazyDefine('emoji-picker', ()=>import('./github/emoji-picker-element'));
lazyDefine('edit-hook-secret', ()=>import('./github/behaviors/edit-hook-secret-element'));
lazyDefine('insights-query', ()=>import('./github/insights/insights-query'));
lazyDefine('remote-clipboard-copy', ()=>import('./github/behaviors/remote-clipboard-copy'));
lazyDefine('series-table', ()=>import('./github/insights/series-table'));
lazyDefine('line-chart', ()=>import('./github/insights/line-chart'));
lazyDefine('bar-chart', ()=>import('./github/insights/bar-chart'));
lazyDefine('column-chart', ()=>import('./github/insights/column-chart'));
lazyDefine('stacked-area-chart', ()=>import('./github/insights/stacked-area-chart'));
lazyDefine('pulse-authors-graph', ()=>import('./github/graphs/pulse-authors-graph-element'));
lazyDefine('stacks-input-config-view', ()=>import('./github/stacks/stacks-input-config-view'));
lazyDefine('community-contributions-graph', ()=>import('./github/graphs/community-contributions'));
lazyDefine('discussion-page-views-graph', ()=>import('./github/graphs/discussion-page-views'));
lazyDefine('discussions-daily-contributors', ()=>import('./github/graphs/discussions-daily-contributors'));
lazyDefine('discussions-new-contributors', ()=>import('./github/graphs/discussions-new-contributors'));
lazyDefine('code-frequency-graph', ()=>import('./github/graphs/code-frequency-graph-element'));
lazyDefine('contributors-graph', ()=>import('./github/graphs/contributors-graph-element'));
lazyDefine('org-insights-graph', ()=>import('./github/graphs/org-insights-graph-element'));
lazyDefine('traffic-clones-graph', ()=>import('./github/graphs/traffic-clones-graph-element'));
lazyDefine('traffic-visitors-graph', ()=>import('./github/graphs/traffic-visitors-graph-element'));
lazyDefine('commit-activity-graph', ()=>import('./github/graphs/commit-activity-graph-element'));
lazyDefine('marketplace-insights-graph', ()=>import('./github/graphs/marketplace-insights-graph-element'));
lazyDefine('user-sessions-map', ()=>import('./github/settings/user-sessions-map-element'));
lazyDefine('reload-after-polling', ()=>import('./github/behaviors/reload-after-polling-element'));
lazyDefine('package-dependencies-security-graph', ()=>import('./github/graphs/package-dependencies-security-graph-element'));
lazyDefine('.js-sub-dependencies', ()=>import('./github/dependencies'));
lazyDefine('network-graph', ()=>import('./github/graphs/network-graph-element'));
lazyDefine('business-audit-log-map', ()=>import('./github/business-audit-log-map-element'));
lazyDefine('inline-machine-translation', ()=>import('./github/localization/inline-machine-translation-element'));
lazyDefine('custom-patterns-filter', ()=>import('./github/secret-scanning/custom-patterns-filter'));
